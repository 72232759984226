import { configureStore } from '@reduxjs/toolkit';
import MailingListSlice from './features/mailingListSlice';
import dashUserSlice from './features/dashUserSlice';
import BankListSlice from 'features/bankListSlice';
export const store = configureStore({
  reducer: {
    mailingList: MailingListSlice,
    dashUser: dashUserSlice,
    bankList:BankListSlice,
  },
});
