// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* * {
  overflow-x: hidden;
} */
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

body::-webkit-scrollbar {
  display: none;
}
.hero-component::-webkit-scrollbar {
  display: none;
  width: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,SAAS;EACT,UAAU;EACV,kCAAkC;EAClC,mCAAmC;EACnC,kCAAkC;EAClC,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":["/* * {\n  overflow-x: hidden;\n} */\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: \"Poppins\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  /* overflow-x: hidden; */\n}\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n.hero-component::-webkit-scrollbar {\n  display: none;\n  width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
