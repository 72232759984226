import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, } from 'react-router-dom';

function ProtectedRoutes({ children }) {
  const { dashUser } = useSelector((store) => store.dashUser);
  if (!dashUser) {
    return <Navigate to='/' />;
  }
  return children;
}

export default ProtectedRoutes;
