import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { interestedTopics } from "../utils/interestedTopics";
import { FormControl } from "./shared/FormControl";
import { sendMailListData } from "../features/mailingListSlice";
import { toast } from "react-toastify";
function MailingList() {
  // const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    interestedTopic: [],
    verifiedRecaptcha: "",
  };

  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      border: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-root": {
      color: "text.primary",
    },
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[a-zA-Z]+$/, "First Name must contain only letters")
      .required("Required"),
    lastName: Yup.string()
      .matches(/^[a-zA-Z]+$/, "Last Name must contain only letters")
      .required("Required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("This is a required field"),
    interestedTopic: Yup.array().min(
      1,
      "Select at least one option of your interest"
    ),
    verifiedRecaptcha: Yup.string().required("Recaptcha is a required field"),
  });

  const onSubmit = async (values, onSubmitProps) => {
    console.log("Form data", values);
    const { email, firstName, lastName, interestedTopic } = values;
    let data = {
      interests: interestedTopic,
      first_name: firstName,
      last_name: lastName,
      email: email,
    };
    try {
      const resp = await dispatch(sendMailListData(data));
      console.log(resp);
      if (resp.meta.requestStatus === "fulfilled") {
        console.log("It went");
        navigate("/congratulations");
        return;
      }
      // onSubmitProps.resetForm();
      onSubmitProps.setFieldValue("verifiedRecaptcha", "verified");
    } catch (error) {
      if (error.response.statusCode === 502) {
        toast.error("Deploying Server. Try again later");
      }
      toast.error(error.response);
      console.log(error.response);
    }

    // setTimeout(() => {
    //   console.log('Delayed for 3 seconds');
    //   console.log('Desola is Amazing!');
    //   onSubmitProps.setSubmitting(false);
    //   navigate('/congratulations');
    // }, 1000);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { isSubmitting, isValid } = formik;
        console.log(formik);
        return (
          <Form>
            <Box
              sx={
                {
                  // border: '2px solid red',
                }
              }
            >
              <FormControl
                control="input"
                label="First Name"
                name="firstName"
                sx={inputStyles}
              />
              <FormControl
                control="input"
                label="Last Name"
                name="lastName"
                sx={inputStyles}
              />

              <FormControl
                control="input"
                label="Email"
                name="email"
                type="email"
                sx={inputStyles}
              />

              <FormControl
                control={"checkbox"}
                label="Kindly select topics you’re interested in"
                name="interestedTopic"
                options={interestedTopics}
              />
              <Box py={1}>
                <ReCAPTCHA
                  sitekey="6Ldp6K0kAAAAAJlieYjm6j2td7Y8gRUEl5_VKTSC"
                  onChange={(value) => {
                    formik.setFieldValue(
                      "verifiedRecaptcha",
                      "recaptcha was verified"
                    );
                  }}
                />
              </Box>

              <Grid container>
                <Grid item xs={12} sm={12} md={6} py={1}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="subscribe"
                    disabled={!isValid || isSubmitting}
                    sx={{
                      "&.MuiButton-root": {
                        textTransform: "Capitalize",
                      },
                      borderRadius: "29px",
                      py: "10px",
                    }}
                    fullWidth
                  >
                    {formik.isSubmitting ? <CircularProgress /> : "Subscribe"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default MailingList;
