import React from "react";
import { Box } from "@mui/material";
import BannerImage from "../assets/banner__dash.jpg";

export const Banner = () => {
  return (
    <Box
      sx={{
        marginTop: "30px",
        backgroundImage: `url(${BannerImage})`,
         backgroundSize: "100% auto", 
        backgroundPosition: "center",
        height:{xs:"200px", sm:"400px", lg:"900px"},
        backgroundRepeat:"no-repeat",
      }}
    />
  );
};
