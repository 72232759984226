import { Button, Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HeroImage from "../assets/dash-hero.svg";

const Hero = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        mt: 6,
        mb:{lg:6},
        padding: { lg: "0 110px", md: "0 100px" ,sm:"0 20px", xs:"0 10px"},
        alignItems: "center",
      }}
    >
      <Grid container sx={{ marginTop: 0, alignItems: "center"}}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box>
            <Typography
              component="h1"
              color="primary"
              sx={{
                fontWeight: "600",
                fontSize: { lg: "3.8rem", md: "3.5rem", xs:"2.6rem", sm:"3.4rem" },
                lineHeight: { lg: "90px", md: "85px", xs: "60px", sm:"84px" },
                overflowX:"hidden",
              }}
            >
              Take control of your finances with
              <Box
                component={"span"}
                onClick={(e) => {
                  if (e.detail === 5 || e.detail === 7) {
                    console.log("first")
                     return navigate("/secret-login");
                  }
                }}
              >
                {" Dash"}
               
              </Box>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                fontWeight: "400",
                fontSize: {lg:"1.5rem", md:"1.5rem", sm:"1.8rem", xs:"1rem"},
                lineHeight: {lg:"40px", md:"50px", sm:"58px"},
                width: {lg:"80%", xs:"100%"},
                marginTop:"4px"
              }}
            >
              Learn how to earn more income, save, spend and invest to live a
              happy and healthy life.
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: {md:"10px", lg:"30px", sm:"10px", xs:"10px"},
                marginBottom:{sm:"25px", xs:"25px"},
              }}
            >
              <Grid
                item
                xs={7}
                sm={8}
                md={8}
                lg={6}
              >
                <Button
                  to="/mailing-list"
                  component={Link}
                  variant="contained"
                  color="secondary"
                  sx={{
                    "&.MuiButton-root": {
                      [theme.breakpoints.down("md")]: {
                        fontSize: "1.3rem",
                      },
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "1rem",
                      },

                      textTransform: "none",
                      textAlign: "center",
                      color: "white",
                      fontSize: "1.3rem",
                      width: "100%",
                      padding: "10px 10px 10px 10px",
                      textDecoration: "none",
                      borderRadius: "6px",
                      boxShadow:"none",
                    },
                  }}
                >
                  Join mailing list
                </Button>
              </Grid>
              <Grid item xs={5} sm={4} md={4} lg={6}>
                <Button
                  component="a"
                  href="https://blog.joindash.app/"
                  variant="outlined"
                  color="secondary"
                  sx={{
                    "&.MuiButton-root": {
                      [theme.breakpoints.down("md")]: {
                        fontSize: "1.3rem",
                      },
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "1rem",
                      },

                      textTransform: "none",
                      textAlign: "center",
                      color: "#F3972D",
                      padding: "10px 10px 10px 10px",
                      textDecoration: "none",
                      fontSize: "1.3rem",
                      width: {lg:"50%",md:"100%", xs:'100%'},
                      borderRadius: "6px",
                    },
                  }}
                >
                  View blog
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box
            component={"div"}
            sx={{
              [theme.breakpoints.down("md")]: {},
              width: {md:"100%", lg:"80%", sm:"100%", xs:"100%" },
              marginLeft:{md:"auto", lg:"auto"}
            }}
          >
            <Box
              component="img"
              src={HeroImage}
              sx={{
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                  maxHeight: "600px",
                  objectFit: "contain",
                },
                width: "100%",
                display: "block",
                marginLeft: "auto",
                objectFit: "cover",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
