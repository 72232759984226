import Footer from "components/Footer";
import Navbar from "components/Navbar";
import {
  Paper,
  Typography,
  Container,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import React from "react";

function Policy() {
  return (
    <>
      <Navbar />
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            padding: "30px",
            marginBottom: "50px",
            borderRadius: "50",
            "&.MuiPaper-root": {
              borderRadius: 9,
            },
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}
          >
            Data Protection And Privacy Policy
          </Typography>

          {/* Section 1 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            1. PREAMBLE
          </Typography>
          <Typography paragraph>
            DUKKA INC. respects your privacy and is committed to protecting your
            data.
          </Typography>

          {/* Section 2 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            2. INTRODUCTION
          </Typography>
          <Typography paragraph>
            This Data Protection and Privacy Policy (the “Privacy Policy”)
            describes your privacy rights regarding DUKKA INC. (“we”, “us” or
            “our”) collection, storage and protection of your personal
            information. It applies to our website and all related sites,
            applications, services, and tools (“Services”) regardless of how you
            access or use them.
          </Typography>
          <Typography paragraph>
            This Privacy Policy applies to all forms of systems, operations, and
            processes within our environment that involve the collection,
            storage, protection and disposal of Personal Information (described
            below). It is provided to help you understand what information we
            collect from you, how we protect it, and your rights to it, amongst
            others.
          </Typography>
          <Typography paragraph>
            This Privacy Policy is strictly restricted to only our Services and
            therefore does not apply to services that are not owned or
            controlled by us, including third-party websites. We are committed
            to handling all Personal Information provided to us in compliance
            with both applicable and model data protection and privacy laws.
          </Typography>

          {/* Section 3 */}
          {/* Add more sections as needed */}

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            3. CONSENT
          </Typography>
          <Typography paragraph>
            By using our Services in any manner, you confirm to us that you have
            read and accepted this Privacy Policy and consent to the data
            practices described in this Privacy Policy. If you do not intend to
            accept this Privacy Policy or do not meet or comply with the
            provisions set forth herein, then you may not use our Services.
          </Typography>

          {/* Section 4 */}
          {/* Add more sections as needed */}

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            4. THE INFORMATION WE COLLECT
          </Typography>
          <Typography
            paragraph
            sx={{
              fontWeight: "bold",
            }}
          >
            A. Personal Information
          </Typography>
          <Typography paragraph>
            As part of our operations, we collect and process certain types of
            information (such as name, telephone numbers, address, PIN, etc.) of
            individuals that make them easily identifiable. These individuals
            include current, past, and prospective registered users, prospective
            users, newsletter applicants, any and all other persons that in one
            way or the other interact with our Platform (“Users”), and other
            individuals with whom we communicate or deal with, jointly and/or
            severally.
          </Typography>
          <Typography paragraph>
            To use our Services, you will be required and hereby undertake to
            voluntarily provide us with certain Personal Information. Personal
            Information refers to information relating to an identified person
            or information that can be used to identify you (e.g., name, email
            address, telephone number).
          </Typography>
          <Typography paragraph>
            We use your Personal Information to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="I. provide you with the required Services
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="II. respond to your questions or requests
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="III. improve features, website content and analyse data to develop products and services
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="IV. address inappropriate use of our Services
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="V. prevent, detect and manage risk against misinformation, wrong use, and illegal activities using internal and third-party screening tools
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="VI.  send you marketing content, newsletters, and service updates curated by us, however, we will provide you with an option to unsubscribe if you do not want to hear from us
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="VII. verify your identity and the information you provide in line with our statutory obligations using internal and third-party tools
 "
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="VIII. maintain up-to-date records
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="IX. resolve disputes that may arise, including investigations by law enforcement or regulatory bodies
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="X. any other purpose that we disclose to you while providing our Services to you
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="XI. import and add a contact list to let you, as a merchant, import the customer contact from your devices.
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="XII. upload images to add a product image to your inventory and user profile.
"
              />
            </ListItem>
          </List>
          <Typography paragraph>
            With your consent, we may collect additional Personal Information in
            other ways including emails, surveys, and other forms of
            communication. Once you begin using our Services, we will keep
            records of and collect information regarding your activities as it
            relates to our Services.
          </Typography>

          <Typography
            paragraph
            sx={{
              fontWeight: "bold",
            }}
          >
            B. Information that we collect from website visitors, not Users
          </Typography>
          <Typography paragraph>
            As a visitor to our website, we do not collect your Personal
            Information. However, so we can monitor and improve our website and
            Services, we may collect non-personally identifiable information.
          </Typography>
          <Typography
            paragraph
            sx={{
              fontWeight: "bold",
            }}
          >
            C. Purpose Limitation
          </Typography>
          <Typography paragraph>
            We collect Personal Information only for identified purposes and for
            which consent has been obtained. Such Personal Information cannot be
            reused for another purpose that is incompatible with the original
            purpose, except consent is obtained for such purpose
          </Typography>
          <Typography
            paragraph
            sx={{
              fontWeight: "bold",
            }}
          >
            D. Data Minimization
          </Typography>
          <Typography paragraph>
            We limit Personal Information collection to data that is relevant,
            adequate, and absolutely necessary for carrying out the purpose for
            which the data is processed. We will evaluate whether and to what
            extent the processing of Personal Information is necessary and where
            the purpose allows, anonymized data will be used.
          </Typography>
          <Typography
            paragraph
            sx={{
              fontWeight: "bold",
            }}
          >
            E. Cookies
          </Typography>
          <Typography paragraph>
            We use cookies to identify you as a User and make your user
            experience easier, customise our Services, content, and advertising,
            and where applicable help you ensure that your account security is
            not compromised. We also use cookies to mitigate risk and prevent
            fraud, promote trust and safety on our website. Cookies allow our
            servers to remember IP addresses, date and time of visits, monitor
            web traffic and prevent illegal activities.
          </Typography>
          <Typography paragraph>
            Our cookies do not store personal or sensitive information. They
            simply hold a unique random reference to you so that once you visit
            our website, we can recognize who you are and provide certain
            content to you. If your browser or browser add-on permits, you have
            the choice to disable cookies on our website, however this may
            impact your experience with using our website.
          </Typography>

          {/* Section 5 */}
          {/* Add more sections as needed */}

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            5. HOW WE PROTECT YOUR INFORMATION
          </Typography>
          <Typography paragraph>
            We shall establish adequate controls in order to protect the
            integrity and confidentiality of your Personal Information, both in
            digital and physical format and to prevent your Personal Information
            from being accidentally or deliberately compromised.
          </Typography>
          <Typography paragraph>
            We are committed to managing your Personal Information in line with
            global industry best practices. We protect your Personal Information
            using physical, technical, and administrative security measures to
            reduce the risks of loss, misuse, unauthorised access, disclosure
            and alteration, we also use industry recommended security protocols
            to safeguard your Personal Information. Other security safeguards
            include but are not limited to data encryption, firewalls, and
            physical access controls to our building and files and only granting
            access to your Personal Information to only employees who require it
            to fulfill their job responsibilities. No Personal Information
            processing will be undertaken by an employee who has not been
            authorised to carry such out as part of their legitimate duties.
          </Typography>

          {/* Section 6 */}
          {/* Add more sections as needed */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            6. CHOICES AND RIGHTS
          </Typography>
          <Typography paragraph>
            Once your Personal Information is held by us, you are entitled to
            reach out to us to exercise the following rights:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="I. the right to request for and access your Personal Information collected and stored. Where data is held electronically in a structured form, such as in a Database, you have a right to receive that data in a common electronic format,
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="II. the right to information on your Personal Information collected and stored,

"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="III. the right to objection or request for restriction,
"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="IV. the right to object to automated decision making,

"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="V. the right to request rectification and modification of your Personal Information which we keep,

"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="VI. the right to request for the deletion of your data,

"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="VII.  the right to request the movement of your data from us to a third party called the right to the portability of data; and


 "
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="VIII.  the right to object to, and to request that we restrict the processing of your Personal Information. We shall inform you of the effect of such restrictions and how it will affect our Services to you.


 "
              />
            </ListItem>
          </List>
          <Typography paragraph>
            Your request will be reviewed by us and carried out except as
            restricted by law or our statutory obligations. You may review and
            update your Personal Information directly or by contacting us via
            email at
            <Typography component="span" sx={{ ml: 0.5 }}>
              <a
                href="mailto:help@dash.com"
                style={{
                  textDecoration: "none",
                  fontSize: "1em",
                  fontWeight: "bold",
                  color: "#EC7E81",
                }}
              >
                help@dash.app
              </a>
            </Typography>
          </Typography>

          {/* Section 6 */}
          {/* Add more sections as needed */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            7. AGE RESTRICTION
          </Typography>
          <Typography paragraph>
            Our Services are directed to people between the ages of 18 and
            above. If as a parent or guardian, you become aware that your child
            or ward has provided us with any information without your consent,
            please contact us via email at
            <Typography component="span" sx={{ ml: 0.5 }}>
              <a
                href="mailto:help@dash.com"
                style={{
                  textDecoration: "none",
                  fontSize: "1em",
                  fontWeight: "bold",
                  color: "#EC7E81",
                }}
              >
                help@joindash.app
              </a>
            </Typography>
          </Typography>

          {/* Section 8 */}
          {/* Add more sections as needed */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            8. UPDATES, MODIFICATIONS AND AMENDMENTS
          </Typography>
          <Typography paragraph>
            We reserve the right to update, modify, change or revise this
            Privacy Policy from time to time. The changes will not be
            retroactive, and the most current version of this Privacy Policy
            which will always be on this page and will continue to govern our
            relationship with you. We advise that you check this page often,
            referring to the date of the last modification on the page.
          </Typography>
          <Typography paragraph>
            We will also try to notify you of any material changes which could
            be done via email associated with your account or service
            notification. By continuing to use our Services after the changes
            become effective, you agree to be bound by the revised Privacy
            Policy.
          </Typography>

          {/* Section 9 */}
          {/* Add more sections as needed */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            9. CONTACT US
          </Typography>
          <Typography paragraph>
            You may contact us upon becoming aware of any breach of Personal
            Information or if your access to our Services has been compromised,
            to enable us to take the necessary steps towards ensuring the
            security of your Personal Information.
          </Typography>
          <Typography paragraph>
            You may also contact us if you have any questions relating to this
            Privacy Policy or would like to find out more about exercising your
            data protection rights. All questions and inquiries may be sent to
            us via email at
            <Typography component="span" sx={{ ml: 0.5 }}>
              <a
                href="mailto:help@dash.com"
                style={{
                  textDecoration: "none",
                  fontSize: "1em",
                  fontWeight: "bold",
                  color: "#EC7E81",
                }}
              >
                help@dash.app
              </a>
            </Typography>
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}

export default Policy;
