import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

function Error() {
  const theme = useTheme();
  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{ flex: "0 0 auto" }}>
        <Navbar />
      </Box>
      <Box sx={{ flex: "1 0 auto" }}>
        <Grid container justifyContent={"center"} sx={{}}>
          <Grid item>
            <Box
              sx={{
                [theme.breakpoints.down("md")]: {
                  width: "94%",
                  padding: "20px",
                },
                width: "70%",
                margin: "0 auto 70px auto",
              }}
            >
              <Box
                variant="h1"
                align={"center"}
                sx={{
                  width: "100%",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "6rem",
                  },
                  fontSize: "6rem",
                }}
              >
                404
              </Box>
              <Typography
                variant="h4"
                align={"center"}
                sx={{
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "2rem",
                  },
                  paddingBottom: "20px",
                }}
              >
                Sorry, the page you're looking for can't be found.
              </Typography>
              <Typography variant="body1" color="initial" align={"center"}>
                It may have been moved or deleted, or you may have typed the URL
                incorrectly. Please check the address and try again, or go back
                to the previous page.
              </Typography>
              <Link to={"/"}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    "&.MuiButton-root": {
                      textTransform: "Capitalize",
                      display: "block",
                      [theme.breakpoints.down("sm")]: {
                        width: "50%",
                      },
                      width: "30%",
                      margin: "30px auto",
                      marginBottom: "0",
                      borderRadius: "29px",
                    },
                  }}
                >
                  Go home
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flex: "0 0 auto" }}>
        <Footer />
      </Box>
    </Box>
  );
}

export default Error;
