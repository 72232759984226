import axios from 'axios';
import { logoutUser } from '../features/dashUserSlice';
import { getUserFromLocalStorage } from './localStorage';

const customFetch = axios.create({
  baseURL: 'https://api.joindash.app',
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

// using interceptors to handle our headers
customFetch.interceptors.request.use((config) => {
  const dashUser = getUserFromLocalStorage();

  if (dashUser) {
    config.headers['Authorization'] = `Token ${dashUser.token.token}`;
  }
  return config;
});

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401) {
    thunkAPI.dispatch(logoutUser());
    return thunkAPI.rejectWithValue('Unauthorized! Logging Out...');
  }
  if (error.response.status === 400) {
    return thunkAPI.rejectWithValue('Invalid email/password');
  }
  console.log(error.response.data);
  return thunkAPI.rejectWithValue(error.response.data);
};

export default customFetch;
