import { Container, Paper, Typography } from '@mui/material';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import React from 'react';

function Terms() {
  return (
    <>
      <Navbar />
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            padding: "30px",
            marginBottom: "50px",
            borderRadius: "50",
            "&.MuiPaper-root": {
              borderRadius: 9,
            },
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            center
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}
          >
            Terms Of Service
          </Typography>
          {/* Section 1 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            1. Introduction
          </Typography>
          <Typography paragraph>
            These website terms and conditions of use and service (“Terms”)
            contained herein on this webpage is a legal agreement between you
            (“you”, “User”) and Dukka Inc. (“Dash App” “we”, “our” or “us”).
            These Terms shall govern your access to and use of our services
            which include the use of our Platform and other products and
            services (collectively referred to as the “Services”).
          </Typography>
          <Typography paragraph>
            These Terms apply in full force and effect to your use of the
            Services and by using any of the Services, you expressly accept all
            terms and conditions contained herein in full and without limitation
            or qualification, including our Data Protection and Privacy Policy.
            We oblige you therefore to thoroughly read these Terms carefully
            before agreeing to be bound by it. You must not use any of the
            Services, if you have any objection to any of these Terms.
          </Typography>

          {/* Section 2 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            2. Eligibility
          </Typography>
          <Typography paragraph>
            By signing on to use our Services, you are warranting that you are
            not a person barred from receiving services under the laws of the
            applicable jurisdiction.
          </Typography>

          {/* Section 3 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            3. Age Restriction
          </Typography>
          <Typography paragraph>
            Our Services are directed to people from the ages of 18 and above.
            You are only permitted to use the Services if you are aged 18 or
            older. We do not knowingly engage people younger than the age of 18.
            If as a parent or guardian, you become aware that your child or ward
            child has provided us with any information without your consent,
            please contact us through the details provided in these Terms.
          </Typography>

          {/* Section 4 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            4. Provision of Services
          </Typography>

          <Typography
            paragraph
            sx={{
              fontWeight: "bold",
            }}
          >
            A. The Service
          </Typography>
          <Typography paragraph>
            The Services include providing digital services to individuals and
            Companies by providing digital, ledger, and bookkeeping solution
            that helps underbanked small businesses in the informal sector to
            accept digital payments, grow, and succeed. With our platform,
            business owners will be able to keep track of sales, inventory and
            expenses. We also provide performance reporting which business
            owners can use as a qualifier to access personal loans, business
            lines of credits, insurance, security and other social securities.
          </Typography>
          <Typography paragraph>
            We provide our services in categories grouped into hardware,
            software, payments and analytics. Our Hardware Services refer to
            Dukka’s all-in-one device, Dukka’s card reader and the user’s
            smartphone device. Our Software Service refers to the Dukka App. Our
            Payment Services refer to cash, bank transfer, card (debit/credit)
            and pay later. Our Analytics Services refer to user data gathered
            and interpreted for business analysis
          </Typography>
          <Typography
            paragraph
            sx={{
              fontWeight: "bold",
            }}
          >
            B. The Service
          </Typography>
          <Typography paragraph>
            In order to access and use some Services, you must download the
            Dukka Software and/or App where you will register and input the
            required details and create an account with us. The Dukka Software
            will also require access to certain features of your
            smartphone/device and your information that will be stored on its
            database. To use our services, you must grant the Dukka Software
            voluntary access to these features.
          </Typography>
          <Typography paragraph>
            We may launch, change, upgrade, impose conditions to, suspend, or
            stop any Services (or any features within the Services) to you or
            subject to other conditions that we may impose in our discretion,
            without prior notice except that in case of a fee-based Service,
            such changes will not substantially adversely affect the paying
            Users in enjoying that Service.
          </Typography>

          {/* Section 5 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            5. Security
          </Typography>
          <Typography paragraph>
            You also agree to maintain the security of your details and account
            and promptly notify us if you discover or suspect that someone has
            accessed your account without your authorization. Your username and
            password are personal to you and may not be shared with any third
            parties. You are responsible for all activities that occur regarding
            your details or otherwise under your account
          </Typography>

          {/* Section 6 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            6. Security
          </Typography>
          <Typography paragraph>
            Unless otherwise stated, we own the intellectual property rights and
            materials of our Site. All text, formatting (including without
            limitation the arrangement of materials on our website and the
            graphics, animation, tools, commercials, music, video, articles,
            sound, copy, trade names, logos and other materials and information
            on the website are subject to our and/or our licensors and
            licensee’s intellectual property rights. We do not grant you any
            right, license, title or interest to any of our intellectual
            property rights which you may or may not have access to.
          </Typography>
          <Typography paragraph>
            This content may not be copied, reverse engineered, decompiled,
            disassembles, modified or reposted to other websites. Nothing on our
            Site should be construed as granting by implication or otherwise,
            any license or right to use any trademark displayed on our website
            without our written permission or that of such third party that may
            own the trademark. You agree to take such actions including
            executing all documents that may be needed to further affirm our
            intellectual property rights.
          </Typography>

          {/* Section 7 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            7. Warranty Disclaimer
          </Typography>
          <Typography paragraph>
            WE TRY TO KEEP OUR WEBSITE AND SERVICES AVAILABLE AT ALL TIMES,
            BUG-FREE AND SAFE, HOWEVER, YOU USE IT AT YOUR OWN RISK. WE PROVIDE
            THE WEBSITE AND THE CONTENT “AS IS'' WITH NO WARRANTY, EXPRESS OR
            IMPLIED, OF ANY KIND (INCLUDING ANY IMPLIED WARRANTY OR CONDITION OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY,
            SECURITY, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY
            RIGHTS).
          </Typography>
          <Typography paragraph>
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO
            WARRANTY THAT OUR WEBSITE, PLATFORM, PRODUCTS AND SERVICES WILL MEET
            YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL BE 100% FRAUD OR FAIL
            PROOF, REMAIN FREE FROM ANY INTERRUPTION, BUGS, INACCURACIES, AND
            ERROR FREE. YOUR USE OF OUR SERVICES ARE AT YOUR OWN RISK AND YOU
            ALONE WILL BE RESPONSIBLE FOR ANY DAMAGE THAT RESULTS IN LOSS OF
            DATA OR DAMAGE TO YOUR COMPUTER SYSTEM. NO ADVICE OR INFORMATION,
            WHETHER ORAL OR WRITTEN OBTAINED BY YOU FROM OUR WEBSITE, PLATFORM
            OR OUR SERVICES WILL CREATE ANY WARRANTY OR CONDITION NOT EXPRESSLY
            STATED. WE WARRANT THAT WE SHALL USE OUR BEST ENDEAVOURS TO ENSURE
            THAT THE SERVICE OPERATES OPTIMALLY AT ALL TIMES IN ACCORDANCE WITH
            BEST INDUSTRY STANDARD.
          </Typography>

          {/* Section 8 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            8. Limitation Of Liability
          </Typography>
          <Typography paragraph>
            UNDER NO CIRCUMSTANCE SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT,
            INCIDENTAL PUNITIVE, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES,
            INCLUDING DAMAGES RESULTING FROM REVENUE LOSS, PROFIT LOSS, USE,
            DATA, GOODWILL , BUSINESS INTERRUPTION OR ANY OTHER INTANGIBLE
            LOSSES (WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
            SUCH) ARISING OUT OF OUR WEBSITE OR SERVICES (INCLUDING, WITHOUT
            LIMITATION TO INABILITY TO USE, OR ARISING FROM THE RESULT OF USE OF
            OUR WEBSITE, PLATFORM OR SERVICES) WHETHER SUCH DAMAGES ARE BASED ON
            WARRANTY, TORT, CONTRACT, STATUTE OR ANY OTHER LEGAL THEORY.
          </Typography>
          <Typography paragraph>
            SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR
            LIMITATIONS ON THE SCOPE AND DURATION OF SUCH WARRANTIES, SO THE
            ABOVE DISCLAIMERS MAY NOT APPLY TO YOU IN THEIR ENTIRETY BUT SHALL
            APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
          </Typography>

          {/* Section 9 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            9. Indemnification
          </Typography>
          <Typography paragraph>
            You hereby indemnify us and undertake to keep us, our staff and
            affiliates indemnified against any losses, damages, costs,
            liabilities and expenses (including without limitation reasonable
            legal fees and expenses) arising out of any breach by you of any
            provision of these Terms or arising out of any claim that you have
            breached any provision of these Terms. You hereby undertake to
            indemnify and hold us harmless from and against any claim, suit or
            proceedings brought against us arising from or in connection with
            violations of Intellectual Property or other rights of third parties
            in relation to your use of the Services.
          </Typography>

          {/* Section 10 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            10. Force Majeure
          </Typography>
          <Typography paragraph>
            Under no circumstances shall we be held liable for any delay or
            failure or disruption of the content or services delivered through
            the Sites resulting directly or indirectly from acts of nature,
            forces or causes beyond our reasonable control, including without
            limitation, Internet failures, computer, telecommunications or any
            other equipment failures, electrical power failures, strikes, labor
            disputes, riots, insurrections, civil disturbances, shortages of
            labor or materials, fires, flood, storms, explosions, acts of God,
            war, governmental actions, orders of domestic or foreign courts or
            tribunals or non-performance of third parties.
          </Typography>

          {/* Section 12 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            12. Updates, Modifications And Amendments
          </Typography>
          <Typography paragraph>
            We reserve the right to update, modify, change or revise these Terms
            from time to time. The changes will not be retroactive, and the most
            current version of the Terms which will always be on this page will
            continue to govern our relationship with you. We advise that you
            check this page often, referring to the date of the last
            modification on the page. We will also try to notify you of any
            material changes which could be done via email associated with your
            account or service notification. By continuing to use our Services
            after the changes become effective, you agree to be bound by the
            revised Terms.
          </Typography>

          {/* Section 13 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            13. Notices
          </Typography>
          <Typography paragraph>
            Without prejudice to other rights accruable to us under these Terms,
            any breach of these Terms will warrant our institution of such
            measures that we deem appropriate to deal with the breach, which may
            include but shall not be limited to suspending or prohibiting your
            access to and/or use of the website, blocking computers using your
            IP address from accessing the website, contacting your internet
            service provider to request that they block your access to the
            website and/or bringing court proceedings against you.
          </Typography>

          {/* Section 14 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            14. Governing Law And Dispute Resolution
          </Typography>
          <Typography paragraph>
            These Terms of Use shall be interpreted and governed by the laws
            currently in force in Delaware, United States of America. We shall
            try to settle all disputes amicably. Any dispute arising out of
            these Terms which cannot be settled, by mutual agreement/negotiation
            within 1 (one) month shall be referred exclusive jurisdiction of
            courts of the State of Delaware.
          </Typography>

          {/* Section 15 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            15. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any complaints, feedback and/or questions about us, our
            Services and/or these Terms, you may contact us at{" "}
            <span
              style={{
                color: "#EC7E81",
              }}
            >
              <a
                href="mailto:support@joindash.app"
                style={{
                  color: "#EC7E81",
                  textDecoration: "none",
                  fontSize: "1em",
                  fontWeight: "bold",
                }}
              >
                support@joindash.app
              </a>
            </span>
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}

export default Terms;
