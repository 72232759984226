import { useState, useEffect } from "react";
import { Payments } from "components/payments/Payments";
import { Typography, Box, Button} from "@mui/material";
import { ReceiveFundsViaCardOrTransfer } from "components/ReceiveFundsViaCardOrTransfer";
import { Loader } from "utils/Loader";
import { getRequestFundLinkDetails } from "service/ReceiveFunds.service";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

export const ReceiveFunds = () => {
  const [requestFundDetails, setRequestFundDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let [searchParams] = useSearchParams();
  const linkId = searchParams.get("link_id");

  useEffect(() => {
    if (!linkId) {
      return;
    }
    const fundRequestLinkDetails = async (linkId) => {
      setIsLoading(true);
      try {
        const { data } = await getRequestFundLinkDetails(linkId);
        setRequestFundDetails(data);
      } catch (error) {
        toast.error("Error fetching transaction details,try again later");
      } finally {
        setIsLoading(false);
      }
    };
    fundRequestLinkDetails(linkId);
  }, [linkId]);

  if (isLoading) return <Loader />;

  return (
    <Payments
      header={
        <Box>
          <Typography
            sx={{
              color: "#0A0D13",
              fontWeight: 600,
              fontSize: { xs: "25px", md: "38px" },
              marginBottom: { xs: "3px", md: "20px" },
              marginTop: { xs: "3px", md: "20px" },
            }}
          >
            Payment
          </Typography>
          <Box sx={{ width: "100%" }}>
            <hr style={{ borderColor: "#DDDDDD" }} />
          </Box>
        </Box>
      }
      paymentHeading={
        <>
          <Typography sx={{ fontWeight: "600", fontSize: "28px", mb: 3 }}>
            Payment Details
          </Typography>
          <Typography sx={{ fontWeight: "400", fontSize: "24px", mb: 2 }}>
            Pay to
          </Typography>
        </>
      }
      requestFundDetails={requestFundDetails}
      dueButton={
        <Button
          sx={{
            "&.MuiButton-root": {
              cursor: "default",
              textTransform: "initial",
              textAlign: "center",
              color: "black",
              padding: "5px 15px 5px 15px",
              textDecoration: "none",
              fontSize: "1.2rem",
              width: "30%",
              borderRadius: "24px",
              backgroundColor: "#f7f7f7",
            },
          }}
        >
          Due
        </Button>
      }
    >
      <Box sx={{ mt: { xs: 1, md: 8 } }}>
        <ReceiveFundsViaCardOrTransfer
          requestFundDetails={requestFundDetails}
          linkId={linkId}
        />
      </Box>
    </Payments>
  );
};
