import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import mailListImage from "assets/mailListImages/mailingList.svg";
import MailForm from "../components/forms/MailForm";

function MailingList() {
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        paddingBottom: "1.5rem",
      }}
    >
      <Container>
        <Grid
          container
          alignItems={"center"}
          columnSpacing={6}
          sx={{
            marginTop: 0,
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            {matches && (
              <Box
                sx={{
                  width: "100%",
                  margin: "20px auto",
                }}
              >
                <Box
                  component={"img"}
                  src={mailListImage}
                  alt="mailList"
                  sx={{
                    width: "100%",
                    maxHeight: "600px",
                    display: "block",
                    objectFit: "cover",
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              component={"div"}
              sx={{
                [theme.breakpoints.up("md")]: {
                  px: 3,
                },
              }}
            >
              <Typography
                component={"h2"}
                color="initial"
                sx={{
                  fontSize: "36px",
                  fontWeight: "600",
                }}
              >
                Join the Mailing List
              </Typography>
              <Typography
                variant="body1"
                color="#33333"
                sx={{ fontWeight: 500 }}
              >
                Join our community today and gain access to our exclusive
                finance content
              </Typography>
              <MailForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default MailingList;
