import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Container, Stack } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import dashLogoFooter from '../assets/dashLogoFooter.svg';
import instagramIcon from '../assets/instagramIcon.svg';
import locationIcon from '../assets/locationIcon.svg';
import twitterIcon from '../assets/twitterIcon.svg';
import facebookIcon from 'assets/facebookIcon.svg';
import youtubeIcon from 'assets/youtubeIcon.svg';
import linkedInIcon from 'assets/linkedInIcon.svg';
import mailIcon from 'assets/mail.svg';

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          justifyContent: 'center',
          alignItems: 'center',
          padding: {
            md: '50px 100px',
            lg: '60px 110px',
            sm: '50px 20px',
            xs: '50px 20px',
          },
        }}
      >
        <Box>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} sm={12} md={6}>
              <Link to='/'>
                <Box
                  component={'img'}
                  src={dashLogoFooter}
                  alt='hero'
                  sx={{
                    [theme.breakpoints.down('sm')]: {
                      height: '3.5rem',
                      marginTop: '1rem',
                    },
                    height: '5rem',
                    display: 'block',
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <Grid
                  container
                  alignItems='center'
                  sx={{
                    marginTop: '1.2rem',
                    marginBottom: '2rem',
                  }}
                >
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    sx={{
                      fontWeight: '400',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={locationIcon}
                      alt='location'
                      sx={{
                        display: 'block',
                        height: '2rem',
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography
                      component={'p'}
                      sx={{
                        color: 'common.white',
                        fontWeight: '400',
                        lineHeight: '29px',
                      }}
                    >
                      42 Saka Tinubu, Victoria Island, Lagos
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems='center'
                  sx={{
                    marginBottom: '2rem',
                  }}
                >
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    sx={{
                      fontWeight: '400',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={mailIcon}
                      alt='phone'
                      sx={{
                        display: 'block',
                        height: '2rem',
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography
                      component={'p'}
                      sx={{
                        color: 'common.white',
                      }}
                    >
                      <a
                        href='mailto:dash@joindash.app'
                        style={{
                          color: '#fff',
                          textDecoration: 'none',
                          fontSize: '1em',
                          fontWeight: '400',
                        }}
                      >
                        dash@joindash.app
                      </a>
                    </Typography>
                  </Grid>
                </Grid>

                {/* Icons stack */}
                <Box
                  sx={{
                    marginTop: '0.5rem',
                    marginBottom: '1rem',
                    // border: '2px solid red',
                  }}
                >
                  <Stack direction={'row'} spacing={5} alignItems={'center'}>
                    {matches && (
                      <Typography
                        component={'span'}
                        sx={{
                          color: 'common.white',
                        }}
                      >
                        Social Media
                      </Typography>
                    )}
                    <Link
                      to={
                        'https://m.facebook.com/TrustDukka?ref=page_internal&_rdr'
                      }
                    >
                      <Box
                        component={'img'}
                        src={facebookIcon}
                        alt='facebook'
                      />
                    </Link>
                    <Link to={'https://twitter.com/joindash'} target='_blank'>
                      <Box component={'img'} src={twitterIcon} alt='twitter' />
                    </Link>
                    <Link to='https://www.linkedin.com/company/dukka'>
                      <Box
                        component={'img'}
                        src={linkedInIcon}
                        alt='linkedIn'
                      />
                    </Link>
                    <Box component={'img'} src={youtubeIcon} alt='youtube' />
                    <Link
                      to='https://www.instagram.com/joindash/'
                      target='_blank'
                    >
                      <Box
                        component={'img'}
                        src={instagramIcon}
                        alt='instagram'
                      />
                    </Link>
                  </Stack>
                </Box>
                {/* Icons stack */}
                <Box
                  sx={{
                    marginTop: '0.5rem',
                    marginBottom: '1rem',
                  }}
                >
                  <Link
                    to={'/policy'}
                    style={{
                      textDecoration: 'none',
                      marginBottom: '30px',
                    }}
                  >
                    <Stack
                      direction={'row'}
                      spacing={5}
                      alignItems={'center'}
                      sx={{
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      {matches && (
                        <Typography
                          component={'span'}
                          sx={{
                            color: 'common.white',
                          }}
                        >
                          Policy
                        </Typography>
                      )}
                    </Stack>
                  </Link>
                </Box>
                <Box
                  sx={{
                    marginTop: '0.5rem',
                    marginBottom: '1rem',
                  }}
                >
                  <Link
                    to='/term'
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Stack direction={'row'} spacing={5} alignItems={'center'}>
                      {matches && (
                        <Typography
                          component={'span'}
                          sx={{
                            color: 'common.white',
                          }}
                        >
                          Terms
                        </Typography>
                      )}
                    </Stack>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Typography
          sx={{
            marginTop: "120px",
            borderTop: "2px solid #FFFFFF",
            bottom: "5px",
            width: "100%",
            height: "90px",
            textAlign: "center",
            color: "#fff",
          }}
        ></Typography> */}
      </Box>
    </>
  );
};

export default Footer;
