import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import customFetch, { checkForUnauthorizedResponse } from '../utils/axios';
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from '../utils/localStorage';

const initialState = {
  isLoading: false,
  dashUser: getUserFromLocalStorage(),
  isUserRegistered: false,
};

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (dashUser, thunkAPI) => {
    try {
      const resp = await customFetch.post('/accounts/', dashUser);
      console.log(resp.data);
      toast.success('You Successfully Signed Up');
      return resp.data;
    } catch (error) {
      console.log(error.response);
      if (error.response.data.email) {
        return thunkAPI.rejectWithValue(error.response.data.email[0]);
      } else {
        return thunkAPI.rejectWithValue('Server down. Please try again later');
      }
    }
  }
);

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (dashUser, thunkAPI) => {
    try {
      const resp = await customFetch.post('/accounts/login/', dashUser);

      console.log(resp.data);
      let userDetails = {
        email: dashUser.email,
        token: resp.data,
      };

      return userDetails;
    } catch (error) {
      console.log(error.response);
    
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

const dashUserSlice = createSlice({
  name: 'dashUser',
  initialState,
  reducers: {
    logoutUser: (state, { payload }) => {
      removeUserFromLocalStorage();
      state.dashUser = null;
    },
    restoreRegisteredUser: (state, { payload }) => {
      state.isUserRegistered = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isUserRegistered = true;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isUserRegistered = false;
        toast.error(payload);
      })
      .addCase(loginUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.dashUser = payload;
        toast.success(`👋🏾 Hello There! ${payload.email}`);

        addUserToLocalStorage(payload);
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.dashUser = null;
        state.isUserRegistered = false;
        toast.error(payload);
      });
  },
});
export const { logoutUser, restoreRegisteredUser } = dashUserSlice.actions;

export default dashUserSlice.reducer;
