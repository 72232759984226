import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
export const SuccessModal = ({ message, openModal, handleClose }) => {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "none" }}
      >
        <Box sx={style}>
          <CheckCircleIcon
            sx={{
              marginInline: "auto",
              width: "100%",
              fill: "#2FBD6A",
              fontSize: "70px",
            }}
          />
          <Box
            id="modal-modal-title"
            component="div"
            sx={{ textAlign: "center", color: "#231F20", fontSize: "15px" }}
          >
         {message}
          </Box>
          <Box sx={{textAlign:"center"}}> 
          <Button
                size="medium"
                variant="contained"
                color="primary"
                type="submit"
                id="subscribe"
                component={Link}
                to="/" 
                sx={{
                  "&.MuiButton-root": {
                    textTransform: "Capitalize",
                  },
                  borderRadius: "5px",
                  py: "10px",
                  fontWeight: 700,
                  fontSize: "18px",
                  mt: { xs: 4, md: 6 },
                  mb: { md: 2 },
                }}
              >
                Back to home
              </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
