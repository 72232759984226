import React from 'react';
import { ThemeProvider } from '@mui/system';
import { theme } from './utils/Theme';
import DashRoutes from './utils/DashRoutes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <DashRoutes />
    </ThemeProvider>
  );
}

export default App;
