import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/material/styles";
import { carousels } from "./Carousel";

const CustomSlider = styled(Slider)`
  & .slick-dots li button:before {
    color: #f1962d;
    font-size: 10px;
  }

  & .slick-dots li.slick-active button:before {
    color: #f1962d;
    font-size: 10px;
  }

  & .slick-dots li {
    margin-bottom: 50px;
  }

  & .slick-dots li:hover button:before {
    color: #f1962d;
    font-size: 10px;
  }
`;

export default function BlogPost() {
  const theme = useTheme();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <CustomSlider {...settings}>
      {carousels.map((carousel) => (
        <Box
          key={carousel.key}
          sx={{
            py: 3,
            mt:{xs:3},
          }}
        >
          <Box
            sx={{ marginBottom: "50px", marginTop:{md: "10px" }, padding:{md:"0 100px", lg:"0, 110px", sm:"0 20px", xs:"0 20px"}}}
          >
            <Grid
              container
              alignItems={"center"}
              sx={{
                marginTop: 0,
                flexDirection: { xs: "column-reverse", md: "row-reverse" },
              }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      margin: "20px auto",
                      width: "100%",
                    },
                    width: "90%",
                    margin: "20px 0 0 10px",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="#7B7D84"
                    sx={{
                      py: 2,
                      [theme.breakpoints.down("md")]: {
                        textAlign: "start",
                      },
                      fontWeight: 500,
                      fontSize: "18px",
                      lineHeight: "35px",
                      
                    }}
                  >
                    {carousel.blogContent}
                  </Typography>

                  <Button
                    component="a"
                    href={carousel.link}
                    variant="contained"
                    color="secondary"
                    sx={{
                      "&.MuiButton-root": {
                        [theme.breakpoints.down("md")]: {
                          fontSize: "1.3rem",
                        },
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "1rem",
                          padding: "10px 20px",
                          marginTop: "20px",
                        },
                        textTransform: "none",
                        width:{sm:"40%", md:"50%", lg:"40%"},
                        textAlign:"center",
                        display: "block",
                        color: "white",
                        // marginRight: "32px",
                        fontSize: "1.3rem",
                        // marginLeft:{sm:"20px", xs:"20px"},
                        // marginRight:{sm:"20px", xs:"20px", md:"32px"},
                        padding: "10px 10px 10px 10px",
                        textDecoration: "none",
                        borderRadius: "6px",
                        boxShadow:"none",
                      },
                    }}
                  >
                    Read more
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  sx={{
                    width: "90%",
                    position: "relative",
                  }}
                >
                  <Box>
                    <img
                      src={carousel.imgSrc}
                      alt="blog"
                      style={{
                        display: "block",
                        width: "100%",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </CustomSlider>
  );
}
