import React from 'react';
import MailingList from '../MailingList';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
function MailForm() {
  return (
    <div>
      <AnimationOnScroll animateOnce={true} animateIn='animate__zoomInDown'>
        <MailingList />
      </AnimationOnScroll>
    </div>
  );
}

export default MailForm;
