import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import confettiImage from "../assets/congrats.svg";

function Congrats() {
  const theme = useTheme();
  return (
    <Box>
      <Grid
        container
        justifyContent={"center"}
        sx={
          {
            // border: '2px solid red',
          }
        }
      >
        <Grid
          item
          sx={
            {
              // border: '2px solid yellow',
            }
          }
        >
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                width: "80%",
              },
              [theme.breakpoints.down("700")]: {
                width: "95%",
                marginBottom: "40px",
              },

              width: "60%",
              margin: "20px auto",
              marginBottom: "100px",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                // border: '2px solid blue',

                // [theme.breakpoints.down('sm')]: {
                //   height: '400px',
                // },
                [theme.breakpoints.down("700")]: {
                  padding: "10px",
                },
                padding: "40px",
                "&.MuiPaper-rounded": {
                  borderRadius: "30px",
                },
              }}
            >
              <Box
                sx={{
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                  },

                  // border: '2px solid red',
                  width: "100%",
                  margin: "20px auto",
                }}
              >
                <Box
                  component={"img"}
                  src={confettiImage}
                  alt="confetti"
                  sx={{
                    // border: '2px solid blue',
                    [theme.breakpoints.down("sm")]: {
                      height: "8rem",
                    },
                    height: "15rem",
                    display: "block",
                    margin: "0 auto",
                  }}
                />

                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "1.8rem",
                    },
                    fontWeight: 600,
                  }}
                >
                  Congratulations!
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "1.8rem",
                    },
                    fontWeight: 600,
                  }}
                >
                  You're now a part of our community.
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    marginTop: "30px",
                  }}
                >
                  Thank you for joining our mailing list. We're thrilled to have
                  you as a subscriber and we can't wait to keep you updated on
                  the latest financial news and offerings
                </Typography>
                <Link to={"/"}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      "&.MuiButton-root": {
                        [theme.breakpoints.down("sm")]: {
                          width: "70%",
                        },
                        textTransform: "Capitalize",
                        display: "block",
                        width: "40%",
                        margin: "30px auto",
                        marginBottom: "0",
                        borderRadius: "29px",
                        textDecoration:"none"
                      },
                    }}
                  >
                    Back to home page
                  </Button>
                </Link>
                {/* <Loader /> */}
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Congrats;
