import { useState } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextError from "./TextError";
import { FormLabel, Box, Typography } from "@mui/material";
import { ErrorMessage } from "formik";

export const DateInput = (props) => {
  const {
    label,
    name,
    error,
    onChange,
    minDate,
    value,
    disableFuture,
    disablePast,
    required,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            marginTop: { xs: "12px", md: "22px" },
            "& .MuiTextField-root": {
              width: "100%",
            },
          }}
        >
          <FormLabel
            component="label"
            htmlFor={name}
            sx={{
              display: "block",
              marginBottom: 2,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                fontWeight: 500,
                color: "black",
                marginBottom: 1,
              }}
            >
              {label}
            </Typography>
          </FormLabel>
          <DesktopDatePicker
            views={["year", "month"]}
            disableFuture={disableFuture}
            disablePast={disablePast}
            value={value}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setOpen(true),
              },
            }}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                fullWidth
                onClick={() => setOpen(true)}
              />
            )}
          />
          <ErrorMessage component={TextError} name={name} />
        </Box>
      </LocalizationProvider>
    </>
  );
};

