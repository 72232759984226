import React from "react";
import CheckboxGroup from "./CheckboxGroup";
import { DateInput } from "./Datepicker";
import { Select } from "./Select";
import { InputComponent } from "./InputComponent";

export const FormControl = ({ control, ...rest }) => {
  switch (control) {
    case "input":
      return <InputComponent {...rest} />;
    case "checkbox":
      return <CheckboxGroup {...rest} />;
    case "date":
      return <DateInput {...rest} />;
    case "select":
      return <Select {...rest} />;
    default:
      return null;
  }
};
