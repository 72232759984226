import React from "react";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FormControl as FormikControl } from "components/shared/FormControl";
import { CircularProgress, Box, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { fetchBanks } from "features/bankListSlice";
import { toast } from "react-toastify";

import { transferFunds } from "service/SendFunds.service";
import { SuccessModal } from "components/Modal";

const initialValues = {
  beneficiary_account_number: "",
  beneficiary_bank_code: "",
};

export const SendFundsForm = ({ paymentDetails, linkId }) => {
  const [openModal, setOpenModal] = useState(false);

  const validationSchema = Yup.object().shape({
    beneficiary_account_number: Yup.string()
      .max(10, "Enter a valid account number")
      .required("This is a required field"),
    beneficiary_bank_code: Yup.string().required("This is a required field"),
  });

  const onSubmit = async (values) => {
    try {
      const { data } = await transferFunds(linkId, values);
      setOpenModal(true);
      return data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;
        if (errorMessage.amount) {
          toast.error(errorMessage.amount[0]);
        } else if (errorMessage.beneficiary_account_number) {
          toast.error(errorMessage.beneficiary_account_number[0]);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
      console.log("Error:", error);
    }
  };

  const dispatch = useDispatch();
  const banks = useSelector((state) => state.bankList.banks);

  useEffect(() => {
    dispatch(fetchBanks());
  }, [dispatch]);

  // const banks2 = [
  //   { label: "Option 1" },
  //   { label: "Option 2" },
  // ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { isSubmitting, isValid } = formik;

        return (
          <Form>
            <Box>
              <FormikControl
                control="input"
                label="Account number"
                name="beneficiary_account_number"
                maxLength={10}
                value={formik.values.beneficiary_account_number.replace(
                  /[^0-9]/gi,
                  ""
                )}
              />

              <FormikControl
                control="select"
                label="Bank name"
                name="beneficiary_bank_code"
                type="text"
                options={banks}
                value={banks.find(
                  ({ value }) =>
                    value === (formik.values.beneficiary_bank_code ?? "")
                )}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("beneficiary_bank_code", value.value);
                  }
                }}
              />

              <Box sx={{ textAlign: "center" }}>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  type="submit"
                  id="subscribe"
                  disabled={!isValid || isSubmitting}
                  sx={{
                    "&.MuiButton-root": {
                      textTransform: "Capitalize",
                    },
                    borderRadius: "5px",
                    py: "10px",
                    fontWeight: 700,
                    fontSize: "18px",
                    mt: { xs: 4, md: 6 },
                    mb: { md: 2 },
                  }}
                  fullWidth
                >
                  {formik.isSubmitting ? <CircularProgress /> : "Redeem"}
                </Button>
              </Box>
            </Box>
            <SuccessModal
              message="Transaction completed!"
              openModal={openModal}
              handleClose={() => setOpenModal(false)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
