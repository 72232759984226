export const interestedTopics = [
    {
      key: 'Investing strategies',
      value: 'investing_strategies',
    },
    {
      key: 'Financial news and current events',
      value: 'financial_news_current_events',
    },
    {
      key: 'Financial Planning',
      value: 'financial_planning',
    },
    {
      key: 'Cryptocurrency and blockchain',
      value: 'cryptocurrency_blockchain',
    },
    {
      key: 'Personal finance',
      value: 'personal_finance',
    },
    {
      key: 'Economic and market analysis',
      value: 'economic_and_market_analysis',
    },
  ];