import { Payments } from "components/payments/Payments";
import { SendFundsForm } from "components/forms/SendFundsForm";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getTransferLinkDetails } from "service/SendFunds.service";
import { toast } from "react-toastify";
import { Loader } from "utils/Loader";

export const SendFunds = () => {
  const [paymentDetails, setPaymentdetails] = useState(null);
  let [searchParams] = useSearchParams();
  const linkId = searchParams.get("link_id");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!linkId) {
      return;
    }
    const fundTransferLinkDetails = async (linkId) => {
      setIsLoading(true);
      try {
        const { data } = await getTransferLinkDetails(linkId);
        setPaymentdetails(data);
      } catch (error) {
        const errorMessage = (error.response?.data?.error?.server[0] || "")
          .split("'")[1]
          ?.replace(/["']\)$/, "");
        toast.error(
          errorMessage || "Error fetching transaction details, try again later"
        );
      } finally {
        setIsLoading(false);
      }
    };
    fundTransferLinkDetails(linkId);
  }, [linkId]);

  if (isLoading) return <Loader />;

  return (
    <Payments
      subText="is sending you"
      buttonText="Redeem"
      paymentDetails={paymentDetails}
    >
      <SendFundsForm paymentDetails={paymentDetails} linkId={linkId} />
    </Payments>
  );
};
