import React, { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const ReceiveFundsViaTransfer = ({ requestFundDetails}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(requestFundDetails?.account_number);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box>
        <Typography sx={{ color: "#ACACAC", fontSize: "18px", mb: 3 }}>
          Transfer NGN {requestFundDetails?.amount} to:
        </Typography>
        <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
          {requestFundDetails?.bank_name}
        </Typography>
        {copied ? (
          <Typography sx={{ fontSize: "20px" }}>
            {requestFundDetails?.account_number}
            <span style={{ color: "#ACACAC", marginLeft: "5px" }}>Copied!</span>
          </Typography>
        ) : (
          <Typography
            sx={{ fontSize: "20px", cursor: "pointer" }}
            onClick={handleCopy}
          >
            {requestFundDetails?.account_number}
            <ContentCopyIcon color="#ACACAC" style={{ color: "#ACACAC" }} />
          </Typography>
        )}
      </Box>

      <Button
        component={Link}
        to="/"
        size="medium"
        variant="contained"
        color="primary"
        type="submit"
        sx={{
          "&.MuiButton-root": {
            textTransform: "Capitalize",
          },
          borderRadius: "5px",
          py: "10px",
          fontWeight: 700,
          fontSize: "18px",
          mt: { xs: 4, md: 7 },
          mb: { md: 2 },
        }}
        fullWidth
      >
        okay, got it
      </Button>
    </Box>
  );
};
