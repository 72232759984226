import TextError from "./TextError";
import { ErrorMessage, Field } from "formik";
import { FormControl, TextField, Box } from "@mui/material";

export const InputComponent = (props) => {
  const {
    label,
    name,
    type = "text",
    error,
    meta,
    field,
    required,
    helperText,
    maxLength,
    ...rest
  } = props;

  return (
    <FormControl
      fullWidth
      sx={{
        marginTop: { xs: "12px", md: "22px" },
        "& .css-13nvzlm-MuiFormHelperText-root": {},
      }}
    >
      <Field name={name}>
        {({ field, form }) => (
          <Box>
            <Box
              htmlFor={name}
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                fontWeight: 500,
                color: "black",
                marginBottom: 2,
              }}
            >
              {label}
            </Box>
            <TextField
              error={form.errors[name] && form.touched[name]}
              id={name}
              type={type}
              name={name}
              helperText={helperText}
              {...field}
              {...rest}
              fullWidth
              inputProps={{
                autoComplete: "off",
                maxLength: maxLength,
              }}
            />
            <ErrorMessage component={TextError} name={name} />
          </Box>
        )}
      </Field>
    </FormControl>
  );
};
