import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { http } from "utils/CustomAxios.js";

const initialState = {
  banks: [],
  loading: false,
  error: null,
};

export const fetchBanks = createAsyncThunk(
  "banks/fetchBanks",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get("/api/v1/dvault/get_banks");
      const updatedData = data.banks.map((item) => {
        return { key: item.bankName, value: item.bankCode };
      });
      return updatedData;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const bankListSlice = createSlice({
  name: "bankList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBanks.fulfilled, (state, action) => {
        state.loading = false;
        state.banks = action.payload;
      })
      .addCase(fetchBanks.rejected, (state, action) => {
        state.loading = false;
        // toast.error(action.payload);
      });
  },
});

export default bankListSlice.reducer;
