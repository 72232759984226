import * as React from "react";
import { TextField, FormControl, FormLabel, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ErrorMessage } from "formik";
import TextError from "./TextError";

export const Select = (props) => {
  const {
    name,
    label,
    options,
    onChange,
    error,
    required,
    field,
    value,
    ...rest
  } = props;

  return (
    <FormControl fullWidth sx={{ marginTop: "18px" }}>
      <Box>
        <FormLabel
          htmlFor={name}
          sx={{
            color: "black",
            fontWeight: 500,
            paddingBottom: "10px",
            fontSize: { xs: "16px", md: "20px" },
          }}
        >
          {label}
        </FormLabel>
        <Autocomplete
          disablePortal
          id={name}
          name={name}
          value={value}
          {...rest}
          defaultValue=""
          isOptionEqualToValue={(option, value) => option.value === value}
          onChange={(_, newValue) => {
            onChange(newValue);
          }}
          options={options}
          getOptionLabel={(option) => (option ? option.key : "")}
          renderInput={(params) => <TextField {...params} />}
        />

        <ErrorMessage component={TextError} name={name} />
      </Box>
    </FormControl>
  );
};
