import React, { useEffect } from "react";
import Hero from "../components/Hero";
import BlogPost from "../components/BlogPost";
// import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { Banner } from "components/Banner";

function Home() {
  // useEffect(() => {
  //   console.log("I am alive");
  // }, []);
  return (
    <>
      <Hero />

      <Banner />

      <BlogPost />
    </>
  );
}

export default Home;
