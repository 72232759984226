import { useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Box,
} from "@mui/material";
import TransferIcon from "assets/payment-icons/transfer-payment.svg";
import CardIcon from "assets/payment-icons/card-payment.svg"
import { ReceiveFundsViaTransfer } from "components/ReceiveFundsViaTransfer";


export const ReceiveFundsViaCardOrTransfer = ({ requestFundDetails }) => {
  const [paymentMethod, setPaymentMethod] = useState("transfer");

  return (
    <Box>
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          id="payment_method"
          sx={{
            color: "#0A0D13",
            marginTop: { xs: "10px", md: "16px" },
            fontWeight: 500,
            fontSize: { xs: "18px", md: "25px" },
          }}
        >
          Pay With:
        </FormLabel>
        <RadioGroup
          sx={{
            mt: { md: 2, xs: 1 },
            mb: 3,
            "& .MuiRadio-root.Mui-checked": {
              color: "#2FBD6A",
            },
          }}
          row
          aria-label="payment_method"
          name="payment_method"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          {/* <FormControlLabel
            value="card"
            control={<Radio />}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "20px" }}>Card</Typography>
                <img src={CardIcon} alt="card" style={{ marginLeft: "8px" }} />
              </Box>
            }
            onClick={() => setPaymentMethod("card")}
          /> */}
          <FormControlLabel
            sx={{ marginLeft: { md: "10px", lg: "10px" } }}
            value="transfer"
            control={<Radio />}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "20px" }}>Transfer</Typography>
                <img
                  src={TransferIcon}
                  alt="transfer"
                  style={{ marginLeft: "8px" }}
                />
              </Box>
            }
            onChange={() => setPaymentMethod("transfer")}
          />
        </RadioGroup>
      </FormControl>
      {paymentMethod === "transfer" && (
        <ReceiveFundsViaTransfer requestFundDetails={requestFundDetails} />
      )}
    </Box>
  );
};
