import { Box, useTheme } from '@mui/material';
import { HashLoader } from 'react-spinners';

const override = {
  display: 'block',
  
  margin: '50px auto',
  // borderColor: 'red',
  // border: '2px solid red',
// height:'20vh',
  position: 'relative',
  // top: '50%',
  left: '0%',
};

export const Loader = () => {
  const theme = useTheme();
  return (
    <Box
    >
      <Box>
        <HashLoader
          color={theme.palette.primary.main}
          loading={true}
          size={200}
          aria-label='Loading Spinner'
          data-testid='loader'
          speedMultiplier={1.5}
          cssOverride={override}
        />
      </Box>
    </Box>
  );
};
