import React from 'react';
import Congrats from '../components/Congrats';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
function Congratulations() {
  return (
    <div>
      <AnimationOnScroll animateOnce={true} animateIn='animate__bounceIn'>
        <Congrats />
      </AnimationOnScroll>
    </div>
  );
}

export default Congratulations;
