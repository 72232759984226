import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import customFetch from '../utils/axios';

const initialState = {
  isLoading: true,
  firstName: '',
  email: '',
  interestedTopic: [],
};

export const sendMailListData = createAsyncThunk(
  'mailingList/sendMailListData',
  async (data, thunkAPI) => {
    try {
      const resp = await customFetch.post('/subscribers/', data);
      console.log(resp);
      return resp.data;
    } catch (error) {
      console.log(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data.email[0]);
    }
  }
);

const mailingListSlice = createSlice({
  name: 'mailingList',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(sendMailListData.fulfilled, (state) => {
        state.isLoading = false;
      
      })
      .addCase(sendMailListData.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(sendMailListData.pending, (state, { payload }) => {
        state.isLoading = true;
      });
  },
});

export default mailingListSlice.reducer;
