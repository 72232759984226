import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SharedLayout from "../components/SharedLayout";
import Error from "../pages/Error";
import ProtectedRoutes from "../pages/ProtectedRoutes";
import Home from "pages/Home";
import MailingList from "pages/MailingList";
import Congratulations from "pages/Congratulations";
import Subscribers from "pages/Subscribers";
import { ReceiveFunds } from "pages/ReceiveFunds";
import { SendFunds } from "pages/SendFunds";
import { Loader } from "./Loader";
import Policy from "pages/Policy";
import Terms from "pages/Terms";








// const Home = React.lazy(() => import('../pages/Home'));
// const MailingList = React.lazy(() => import('../pages/MailingList'));
// const Congratulations = React.lazy(() => import('../pages/Congratulations'));
const Login = React.lazy(() => import("../pages/Login"));
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
// const Subscribers = React.lazy(() => import('../pages/Subscribers'));
// const Signup = React.lazy(() => import('../pages/Signup'));

// export function ScrollToTop() {
// const { pathname } = useLocation();
// useEffect(() => {
//   window.scrollTo(0, 0);
// }, [pathname]);
// return null;
// }

function DashRoutes() {
  return (
    <Router>
      {/* <ScrollToTop> */}
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <LandingPage />
            </Suspense>
          }
        />
        <Route path={"/join-dash"} element={<SharedLayout />}>
          <Route index element={<Home />} />
          <Route path="mailing-list" element={<MailingList />} />
          <Route path="congratulations" element={<Congratulations />} />

          <Route
            path="secret-login"
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="subscribers"
            element={
              <ProtectedRoutes>
                <Subscribers />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path="receive-funds" element={<ReceiveFunds />} />
        <Route path="send-funds" element={<SendFunds />} />
        <Route path="policy" element={<Policy />} />
        <Route path="term" element={<Terms />} />
        <Route path="*" element={<Error />} />
      </Routes>
      {/* </ScrollToTop> */}
    </Router>
  );
}

export default DashRoutes;
