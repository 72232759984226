import SearchIcon from '@mui/icons-material/Search';
// import FilterListIcon from '@mui/icons-material/FilterList';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutUser } from '../features/dashUserSlice';
import customFetch from '../utils/axios';

const columns = [
  { id: 'sn', label: 'S/N', minWidth: 70, align: 'left' },
  { id: 'firstName', label: 'First Name', minWidth: 170, align: 'left' },
  { id: 'lastName', label: 'Last Name', minWidth: 170, align: 'left' },
  { id: 'email', label: 'Email', minWidth: 170, align: 'left' },
  { id: 'interestedTopic', label: 'Interests', minWidth: 170, align: 'left' },
];

function createData(sn, firstName, lastName, email, interestedTopic) {
  return {
    sn,
    firstName,
    lastName,
    email,
    interestedTopic,
  };
}

// const rows = [
//   createData('1', 'Alaba', 'Dammie', 'Dammie@gmail.com', ['Goat', 'Cow']),
//   createData('2', 'Alaba', 'Telko', 'Telco@gmail.com', ['Goat', 'Cow']),
//   createData('3', 'Alaba', 'Mailo', 'Mailo@gmail.com', ['Goat', 'Cow']),
//   createData('4', 'Alaba', 'Ado', 'Ado@gmail.com', ['Goat', 'Cow']),
// ];

function Subscribers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSearch, setLocalSearch] = useState('');
  let url = '/subscribers/';
  const [allSubscribers, setAllSubscribers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const [interestFilter, setInterestFilter] = useState({
    investingStrategies: false,
    financialPlaning: false,
    personalFinance: false,
    financialNews: false,
    cryptocurrency: false,
    economicAndMarket: false,
  });

  const getAllSubscribers = async () => {
    try {
      if (localSearch) {
        url =
          url +
          `?search=${localSearch}&page_size=${rowsPerPage}&page=${page + 1}`;
      } else {
        url = url + `?page_size=${rowsPerPage}&page=${page + 1}`;
      }

      const resp = await customFetch.get(url);

      console.log(resp.data);
      const newData = resp.data.results.map((subscriber, index) => {
        const { email, first_name, last_name, interests } = subscriber;

        return createData(
          page * rowsPerPage + index + 1,
          first_name,
          last_name,
          email,
          interests
        );
      });
      console.log(newData);
      setAllSubscribers(newData);
      setTotalCount(resp.data.count);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logoutUser());

        navigate('/secret-login');
        return toast.error('Unauthorized! Logging Out...');
      } else {
        console.log(error.response);
        toast.error('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    const debounceId = setTimeout(() => {
      getAllSubscribers();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [localSearch, page]);

  const handleLocalChange = (e) => {
    setLocalSearch(e.target.value);
    console.log(e.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log('handleChangePage runs');
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    console.log('handleChangeRowsPerPage runs too');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event) => {
    console.log(event.target);
    setInterestFilter({
      ...interestFilter,
      [event.target.name]: event.target.checked,
    });
  };
  const {
    investingStrategies,
    financialPlaning,
    personalFinance,
    financialNews,
    cryptocurrency,
    economicAndMarket,
  } = interestFilter;

  const open = Boolean(anchorEl);
  const id = open ? ' filter-popover' : undefined;

  return (
    <Box>
      <Container
        sx={
          {
            // border: '2px solid red'
          }
        }
      >
        <Box
         
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <TextField
              id='search'
              value={localSearch}
              onChange={handleLocalChange}
              sx={{
                '& .MuiTextField-root': {
                  width: 200,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                placeholder: 'First name/Email',
              }}
            />
            {/* <Button
              variant='outlined'
              startIcon={<FilterListIcon />}
              aria-describedby={id}
              onClick={handleClick}
            >
              Filter
            </Button> */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {/* <Typography sx={{ p: 2 }}>The content of the dddd</Typography> */}
              <FormControl
                sx={{ m: 1 }}
                component='fieldset'
                variant='standard'
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={investingStrategies}
                        onChange={handleCheckboxChange}
                        name='investingStrategies'
                      />
                    }
                    label='Investing Strategies'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={financialPlaning}
                        onChange={handleCheckboxChange}
                        name='financialPlaning'
                      />
                    }
                    label='Financial Planning'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={personalFinance}
                        onChange={handleCheckboxChange}
                        name='personalFinance'
                      />
                    }
                    label='Personal Finance'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={financialNews}
                        onChange={handleCheckboxChange}
                        name='financialNews'
                      />
                    }
                    label='Financial News'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cryptocurrency}
                        onChange={handleCheckboxChange}
                        name='cryptocurrency'
                      />
                    }
                    label='Cryptocurrency'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={economicAndMarket}
                        onChange={handleCheckboxChange}
                        name='economicAndMarket'
                      />
                    }
                    label='Economic and Market Analysis'
                  />
                </FormGroup>
              </FormControl>
            </Popover>
          </Stack>
        </Box>
        <Box
          sx={{
            my: 6,
          }}
        >
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label='sticky table'
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {allSubscribers && (
                <TableBody>
                  {allSubscribers.map((row, index) => {
                    const { email } = row;

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={email + index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === 'interestedTopic'
                                ? value.join(',  ')
                                : column.id === 'firstName' ||
                                  column.id === 'lastName' ||
                                  column.id === 'email'
                                ? value.charAt(0).toUpperCase() + value.slice(1)
                                : value}
                            </TableCell>
                          );
                        })}
                        {/* <TableCell align='left'>{index + 1}</TableCell>
                          <TableCell align='left'>{first_name}</TableCell>
                          <TableCell align='left'>{last_name}</TableCell>
                          <TableCell align='left'>{email}</TableCell>
                          <TableCell align='left'>
                            {interests.join(', ')}
                          </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component='div'
            count={0 || totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default Subscribers;
