import { createTheme } from "@mui/material/styles";

const dashBlue = "#14243B";
const dashOrange = "#F1962D";
const dashWhite = "#FFFFFF";
// const dashFont = "'Poppins', sans-serif";

export const theme = createTheme({
  palette: {
    common: {
      blue: `${dashBlue}`,
      orange: `${dashOrange}`,
      white: `${dashWhite}`,
    },
    primary: {
      main: `${dashBlue}`,
    },
    secondary: {
      main: `${dashOrange}`,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});
