import React from "react";
import { Box, useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid, Container } from "@mui/material";
import PaymentAvatar from "assets/payment-icons/payment-avatar.svg";
import { Link } from "react-router-dom";
import SecureIcon from "assets/payment-icons/secure-icon.svg";
import logo from "assets/dashLogo.svg";

export const Payments = ({
  subText,
  header,
  children,
  paymentDetails,
  paymentHeading,
  requestFundDetails,
  dueButton,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        overflow: "auto",
        height: "100vh",
      }}
    >
      <Container>
        <Link to="/">
          <Box
            component={"img"}
            src={logo}
            alt="dash"
            sx={{
              [theme.breakpoints.down("sm")]: {
                height: "40px",
              },
              height: "50px",
              py: ".9rem",
              mt: { xs: 2, md: 6 },
              mb: { xs: 2 },
            }}
          />
        </Link>
        <Grid
          container
          spacing={{ xs: 5, md: 2 }}
          sx={{
            alignItems: { md: "center" },
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={{ width: { xs: "100%", md: "80%" } }}>
              {header}
              {children}
              <Typography
                sx={{
                  color: "#ACACAC",
                  fontWeight: 500,
                  fontSize: { xs: "14px", md: "17px" },
                  lineHeight: { xs: "20px", md: "24px" },
                  mt: { xs: 2, md: 2 },
                  pb: { xs: 5, md: 3 },
                }}
              >
                Your personal data will be used to process your payment, support
                your experience throughout this website, and for other purposes
                described in our privacy policy.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "26px",
                padding: "30px 40px",
                width: { md: "65%" },
                height: "auto",
                textAlign: "center",
                marginLeft: { md: "auto", lg: "auto" },
              }}
            >
              <Box>
                <>
                  <div>{paymentHeading}</div>

                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: "160px",
                      height: "160px",
                      overflow: "hidden",
                      marginInline: "auto",
                    }}
                  >
                    <Box
                      component="img"
                      src={paymentDetails?.user?.photo || PaymentAvatar}
                      alt="payment-icon"
                      sx={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{ fontWeight: "600", fontSize: "26px", mt: 2 }}
                  >
                    {paymentDetails?.user?.tag || requestFundDetails?.user?.tag}
                  </Typography>
                  <Typography sx={{ fontWeight: "400", fontSize: "18px" }}>
                    {subText}
                  </Typography>
                  <Typography
                    component="h2"
                    sx={{ fontWeight: "600", fontSize: "30px", mt: 2, mb: 2 }}
                  >
                    {paymentDetails?.currency}
                    <Typography
                      component="span"
                      sx={{ fontSize: "42px", fontWeight: "700px" }}
                    >
                      {paymentDetails?.amount}
                    </Typography>
                  </Typography>
                  <Typography
                    component="p"
                    sx={{ color: "#A3A3A3", fontSize: "18px", mb: 3 }}
                  >
                    {paymentDetails?.narration || requestFundDetails?.narration}
                  </Typography>

                  {dueButton}
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                      color: "black",
                      padding: "5px 15px 5px 15px",
                      textDecoration: "none",
                      fontSize: "1rem",
                      width: "fit-content",
                      marginInline: "auto",
                      borderRadius: "3px",
                      backgroundColor: "#f7f7f7",
                    }}
                  >
                    <Box
                      component="img"
                      src={SecureIcon}
                      alt="secure-icon"
                      sx={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                    />
                    <Box>This is a secure payment link</Box>
                  </Box>
                </>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
