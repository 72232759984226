import { useTheme } from "@mui/material";
import { Box} from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/dashLogo.svg";

function Navbar() {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          marginTop: "44px",
          padding: {md:"0 100px", lg :"0 110px", sm:"0 20px", xs:"0 20px"}
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width:'100%',
          }}
        >
          <Link to="/">
            <Box
              component={"img"}
              src={logo}
              alt="dash"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  height: "40px",
                },
                height: "50px",
                py: ".9rem",
              }}
            />
          </Link>

          <Box
            component="a"
            alt="blog"
            href="https://blog.joindash.app/"
            sx={{
              [theme.breakpoints.down("sm")]: {
                height: "40px",
              },
              height: "60px",
              py: ".9rem",
              fontSize:"26px",
              color:"#14243B",
              fontWeight:600,
              textDecoration:"none",
              "&:hover": {
                color: "#F1962D", 
              },
            }}
          >
            Blog
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Navbar;
