import moneyImage from "../assets/blogImages/BlogThree/how to make money.png";
import manageMoney from "../assets/blogImages/manage-your-money.png";
import saveMoney from "../assets/blogImages/save-money.png";
import strongFinance from "../assets/blogImages/strong-financial-foundation.png";

export const carousels = [
  {
    key: 1,
    imgSrc: moneyImage,
    link:"https://blog.joindash.app/2023/04/26/how-to-make-money-in-one-hour",
    blogContent:
      "Have you ever found yourself in a situation where you needed to make some extra cash fast? Maybe you're facing an unexpected expense, or perhaps you just want to boost your savings account.",
  },
  {
    key: 2,
    imgSrc: manageMoney,
    link:"https://blog.joindash.app/2023/05/11/how-to-manage-your-money-50-30-20-rule/",
    blogContent:
      "Managing your money can be a daunting task, especially if you’re not sure where to start. The good news is that with a little bit of knowledge and discipline, you can take control of your finances and make your money work for you",
  },
  {
    key: 3,
    imgSrc: saveMoney,
    link:"https://blog.joindash.app/2023/05/31/how-to-save-money-as-a-student/",
    blogContent:
      "As a student, navigating the realm of personal finance can often feel like a daunting task. Balancing the demands of academics, social life, and limited financial resources can be challenging. ",
  },
  // {
  //   key: 4,
  //   imgSrc: strongFinance,
  //   link:"",
  //   blogContent:
  //     "In an era of economic uncertainty and evolving financial landscapes, establishing a solid foundation for your personal finances has become more crucial than ever. ",
  // },
];

