export const addUserToLocalStorage = (dashUser) => {
  localStorage.setItem('dashUser', JSON.stringify(dashUser));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem('dashUser');
  localStorage.removeItem('_grecaptcha');
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem('dashUser');
  const dashUser = result ? JSON.parse(result) : null;
  return dashUser;
};
