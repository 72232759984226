import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";
import TextError from "./TextError";

function CheckboxGroup({ label, name, options, ...rest }) {
  const { values } = useFormikContext();
  return (
    <Box sx={{ mt: 3 }}>
      <Box
        component="label"
        htmlFor={name}
        sx={{
          display: "block",
          py: 1,
        }}
      >
        <Typography
          component={"span"}
          sx={{
            fontWeight: 500,
          }}
        >
          {label}
        </Typography>
      </Box>
      <FormGroup>
        <Grid container>
          {options.map((option) => {
            return (
              <Grid key={option.key} item xs={12} sm={12} md={6}>
                <Field
                  type="checkbox"
                  name={name}
                  value={option.value}
                  key={option.key}
                  as={FormControlLabel}
                  sx={{
                    "&.MuiTypography-root": {
                      fontSize: 16,
                    },
                    "&.MuiFormControlLabel-label": {
                      fontSize: 16,
                    },
                    margin: "15px 0"
                  }}
                  control={
                    <Checkbox
                      checked={values.interestedTopic.includes(option.value)}
                    />
                  }
                  label={option.key}
                />
              </Grid>
            );
          })}
        </Grid>

        <FormGroup>
          {/* <Field name={name}>
            {({ field }) => {
              console.log(field);
              return options.map((option) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={option.value}
                        value={option.value}
                        checked={field.value.includes(option.value)}
                        {...field}
                        {...rest}
                      />
                    }
                    label={option.key}
                    key={option.key}
                  />
                );
              });
            }}
          </Field> */}
        </FormGroup>
      </FormGroup>

      {/* <Field name={name}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    id={option.value}
                    value={option.value}
                    checked={field.value.includes(option.value)}
                  />
                }
                key={option.key}
              />
            );
          });
        }}
      </Field> */}
      <ErrorMessage component={TextError} name={name} />
    </Box>
  );
}

export default CheckboxGroup;

// formik-checkbox Helpful resource
// https://stackoverflow.com/questions/70165035/how-to-use-material-ui-checkbox-with-formik
